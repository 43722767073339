<template>
  <RecordViewWrapper>
    <sdPageHeader title="Notification">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary" @click="readAllHandler">
          <sdFeatherIcons type="check" size="14" /> Tandai Semua Sudah Dibaca
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          />
        </div>
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { DataService } from '@/config/dataService/dataService';

const columns = [
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'name',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'divisi_id',
  },
  {
    title: 'User',
    dataIndex: 'user_from',
    key: 'divisi_id',
  },
];

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);

    onMounted(() => {
      dispatch('axiosCrudGetData', 'notification');
    });

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const { time, message, user_from, read_at, url, id } = data;

            return {
              key: key + 1,
              time: !read_at ? <strong>{time}</strong> : time,
              message: !read_at ? (
                message ? (
                  <span onClick={() => readHandler(id)}>
                    <router-link to={url}>
                      <strong>{message.replace(/(<([^>]+)>)/gi, '')}</strong>
                    </router-link>
                  </span>
                ) : (
                  ''
                )
              ) : message ? (
                <span>
                  <router-link to={url}>{message.replace(/(<([^>]+)>)/gi, '')}</router-link>
                </span>
              ) : (
                ''
              ),
              user_from: !read_at ? <strong>{user_from}</strong> : user_from,
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'notification', filter: formState.searchItem });
    };

    const readHandler = id => {
      DataService.get(`read-notification/${id}`).then(() => {
        dispatch('axiosCrudGetData', 'notification');
      });
    };

    const readAllHandler = () => {
      DataService.get('read-all-notification').then(() => {
        dispatch('axiosCrudGetData', 'notification');
      });
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleSearch,
      readAllHandler,
    };
  },
};

export default ViewPage;
</script>
